import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "poles"
    }}>{`Poles`}</h1>
    <h2 {...{
      "id": "team-poles"
    }}>{`Team Poles`}</h2>
    <p>{`There is a team pole in each team base next to the spawn. Capture the pole of your opponents to win the game.
The team pole can be captured only when its team has no guardians left.`}</p>
    <p><img alt="A Team Pole" src={require("../public/team-pole.jpg")} /></p>
    <p style={{
      "textAlign": "center",
      "fontSize": "0.8em",
      "marginTop": "-25px"
    }}><em>A Team Pole</em></p>
    <h2 {...{
      "id": "bonus-pole"
    }}>{`Bonus Pole`}</h2>
    <p>{`At the start of the game, a random bonus is selected. This bonus is displayed in the top left corner of the screen.`}</p>
    <p><img alt="Bonus" src={require("../public/bonus.jpg")} /></p>
    <p>{`When a player is loading the bonus pole, he gains 2 coppers every two seconds and his teammates gain also 2 coppers. Once the pole is loaded, the team who has loaded it receives the bonus.
After being loaded, the pole slowly unloads. Once unloaded, a new random effect is picked and the pole can be loaded again.`}</p>
    <p>{`The permanent bonus unlocked by a team are displayed on the `}<a parentName="p" {...{
        "href": "/stats-menu"
      }}>{`Stats Menu`}</a>{`.  `}</p>
    <p><img alt="The Bonus Pole" src={require("../public/bonus-pole.jpg")} /></p>
    <p style={{
      "textAlign": "center",
      "fontSize": "0.8em",
      "marginTop": "-25px"
    }}><em>The Bonus Pole</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      